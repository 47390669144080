import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from './article-list.module.scss'
import Article from './article'

const ArticleList = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                image
                keywords
              }
              excerpt
              id
            }
          }
        }
      }
    `}
    render = {
        data => (
            <div className={styles.container}>
                <h1>What type of glass you need?</h1>
                {data.allMarkdownRemark.edges.map(({node}) => (
                        <Article id={node.id}
                            title={node.frontmatter.title}
                            image={node.frontmatter.image} 
                            excerpt={node.excerpt} />
                ))}
            </div>
        )
    }
  ></StaticQuery>
)

export default ArticleList

