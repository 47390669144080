import React from 'react';
import styles from './header.module.scss';
import {Link, StaticQuery, graphql} from 'gatsby';

const HeaderLink = (props) => (
    <Link className={styles.link} to={props.to}>{props.text}</Link>
)

export default () => (
    <StaticQuery 
        query = {
            graphql `
                query{
                    site {
                      siteMetadata {
                        title
                        tagline
                      }
                    }
                }
                  
            `
        }
        render = {
            data => (
                <header className={styles.container}>
                    <div className={styles.row}>
                        <h1 className={styles.title}><HeaderLink to="/" text={data.site.siteMetadata.title} /></h1>
                        <p className={styles.tagline}>{data.site.siteMetadata.tagline}</p>
                    </div>
                    <div className={styles.row}>
                        <HeaderLink to="/" text="Home" /> 
                        <HeaderLink to="/contact" text="Contact" />
                    </div>
                </header>
            )
        }
    />
)