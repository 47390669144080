import React from "react"
import styles from './article.module.scss'

const Article = (props) => (
    <article className={styles.container} key={props.id}>
        <div className={styles.image}>
            <img src={props.image} />
        </div>
        <div className={styles.text}>
            <h1 className={styles.title}>{props.title}</h1>
            <p className={styles.excerpt}>
                {props.excerpt}  
            </p>
        </div>
    </article>
)

export default Article

