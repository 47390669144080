import React from 'react';
import styles from './footer.module.scss';

export default () => {
    return (
        <footer className={styles.container}>
            <div className={styles.footer}>
                <span>© 2000-2025 DawnMax Pvt. Ltd. All Rights Reserved.</span>
            </div>
        </footer>
    );
}