import React from "react";
import Layout from "../components/layout";
import Title from '../components/title';
import ArticleList from '../components/article-list';

export default () => (
<Layout>
    <Title text="Welcome!!! Let us introduce ourselves!!!"></Title>
    <p>One of the first goals a new construction company sets out to achieve needs to be establishing a strong and sustainable internal structure. Just how a construction company organizes itself depends on its size and the scope of its projects. For example, a company that develops its own projects and makes proposals to clients needs to set up a project-development team that includes members who are skilled at giving formal presentations. Hiring the right workers and defining their job descriptions are also key parts of structuring a new construction company.</p>
    <ArticleList />
</Layout>
)
